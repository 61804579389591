



















































































































































import Vue from "vue";
import LayoutWithPageHeader from "@/components/layout/LayoutWithPageHeader.vue";
import WorkspaceLogo from "@/components/layout/WorkspaceLogo.vue";
import { ActionTypes } from "@/store/action-types";
import { GroupedInvoices } from "@/store/modules/organization";
import { InvoiceStatus } from "@/generated/graphql";
import {
  BIconCaretRightFill,
  BIconDownload,
  BIconCheck,
  BIconClockFill,
  BIconStickyFill
} from "bootstrap-vue";

export default Vue.extend({
  components: {
    LayoutWithPageHeader,
    WorkspaceLogo,
    BIconCaretRightFill,
    BIconDownload,
    BIconCheck,
    BIconClockFill,
    BIconStickyFill
  },
  data() {
    return {
      InvoiceStatus,
      fields: [
        { key: "workspace", label: "Workspace" },
        { key: "plan", label: "Plan" },
        { key: "number", label: "Number" },
        { key: "issueDate", label: "Date" },
        { key: "dueDate", label: "Due date" },
        { key: "paidDate", label: "Payment date" },
        { key: "status", label: "Status", class: "text-center" },
        { key: "amount", label: "Amount", class: "text-right" }
      ],
      expandedRows: {} as { [key: string]: boolean }
    };
  },
  created() {
    this.$storeTyped.dispatch(ActionTypes.FETCH_INVOICES);
  },
  computed: {
    invoices(): GroupedInvoices[] | null {
      return this.$storeTyped.getters.getInvoicesByMonthYear;
    }
  },
  methods: {
    download(url: string) {
      window.open(url);
    },
    toggleRowDetails(row: string) {
      this.expandedRows = {
        ...this.expandedRows,
        [row]: !this.expandedRows[row]
      };
    }
  }
});
